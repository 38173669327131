<template>
  <div id="header-text">
    <span id="microphone" class="pi pi-microphone"></span>
    <h1 v-if="intro?.title">{{ intro.title }}</h1>
    <h2 v-if="intro?.subtitle" v-html="intro.subtitle"></h2>
  </div>

  <div class="intro-descr" v-if="intro">
    <h3 v-if="intro?.intro">{{ intro.intro }}</h3>
    <p v-if="intro?.description" v-html="intro.description"></p>
  </div>

  <div class="intro-menu">
    <!-- Varianta pro velké displeje -->
    <div class="horizontal-menu" v-if="!isMobile">
      <div 
        v-for="(item, index) in menuItems" 
        :key="index" 
        @click="selectItem(item)"
        :class="{ 'menu-item': true, active: item === selectedItem }"
      >
        {{ item.label }}
      </div>
    </div>

    <!-- Varianta pro mobilní displeje -->
    <div class="horizontal-menu-phone" v-else>
      <select v-model="selectedItem" name="menuItems" id="menuItems">
        <option 
          v-for="(item, index) in menuItems" 
          :value="item" 
          :key="index"
          :class="{ active: item === selectedItem }"
        >
          {{ item.label }}
        </option>
      </select>
    </div>

    <!-- Obsah vybrané položky -->
    <div class="content-container" v-if="selectedItem">
      <div class="image-container" v-if="selectedItem.image">
        <img 
          :src="selectedItem.image" 
          alt="Selected Item" 
        />
      </div>

      <div class="text-container">
        <h2>{{ selectedItem.title }}</h2>
        <p v-html="selectedItem.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheIntroduction",
  props: {
    intro: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      menuItems: this.intro?.sections || [],
      selectedItem: null,
      isMobile: false,
    };
  },
  created() {
    this.handleResize(); 
    window.addEventListener("resize", this.handleResize);

    if (this.menuItems.length > 0) {
      this.selectedItem = this.menuItems[0];
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 900;
    },
  },
};
</script>

<style scoped>
#header-text {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

#microphone {
  font-size: 55px !important;
  color: rgb(255, 168, 0);
}

#header-text h2 {
  color: #b8bcc3;
  line-height: normal;
  font-weight: 400;
  font-size: 15px;
}

.intro-descr {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 5%;
  margin-right: 5%;
  text-align: justify;
}

.intro-descr h3 {
  text-align: center;
  margin-bottom: 10px;
}

.intro-menu {
  background-color: #012E40;
  color: white;
  padding-bottom: 30px;
}

.horizontal-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 7px;
  border: 0.5px solid #b8bcc3;
  font-weight: bold;
  margin-top: 46px;
  margin-bottom: 20px;
}

.menu-item:hover {
  color: white;
  background-color: rgb(255, 168, 0);
}

.menu-item.active {
  background-color: rgb(255, 168, 0);
  color: white;
  border: 0;
}

.horizontal-menu-phone {
  text-align: center;
}

select {
  border: 0.5px solid rgb(255, 168, 0);
  padding: 10px;
  color: black;
  background-color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 2px;
}

.content-container {
  display: flex;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  align-items: flex-start;
}

.image-container {
  flex: 0 0 40%;
  max-width: 40%;
  text-align: left;
  margin-right: 20px;
}

.image-container img {
  width: 100%;
  height: auto;
}

.text-container {
  flex: 1;
  padding-left: 20px;
  text-align: left;
}

.text-container h2 {
  margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .content-container {
    flex-direction: column;
  }

  .image-container,
  .text-container {
    max-width: 100%;
    flex: none;
    margin-right: 0;
    padding-left: 0;
    text-align: center;
  }

  .text-container h2 {
    text-align: center;
  }

  .image-container img {
    max-width: 70%;
  }

  .horizontal-menu {
    display: none;
  }

  .intro-menu {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
